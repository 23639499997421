.film {
  background: $black;
  width: 100%;
  padding-bottom: 20vh;
  height: auto;
  color: $white;
  .swiper-button-prev,
  .swiper-button-next {
    color: white;
  }
  .video-container {
    width: 100%;
    height: 80vh;
    opacity: 1;
    object-fit: cover;
    top: 0;
    left: 0;
    img {
      width: 100%;
      position: relative;
      object-fit: cover;
      height: 75vh;
    }
    .play-video-btn {
      position: absolute;
      z-index: 14;
      width: 80px;
      height: 80px;
      top: 35%;
      left: 47%;
      cursor: pointer;
    }
  }
  .film-text {
    width: 80%;
    margin-left: 10%;
    text-align: center;
    .film-title {
      font-size: 18px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-weight: bold;
    }
    .white-line {
      height: 3px;
      width: 100%;
      border-bottom: 1px solid $white;
    }
    .film-year {
      padding-top: 5px;
      font-size: 12px;
      font-weight: lighter;
    }
    .film-description {
      padding-top: 15px;
      font-size: 12px;
      font-weight: lighter;
      a {
        margin-left: 5px;
        font-size: 12px;
        color: white;
        text-decoration: none;
        font-weight: 900;
        border-bottom: 1px solid white;
        padding: 5px;
      }
    }
    .film-written-label {
      padding-top: 15px;
      font-size: 16px;
      font-weight: bold;
    }
    .film-written {
      font-size: 12px;
      font-weight: lighter;
      padding-bottom: 10px;
    }
    .film-starring-label {
      padding-top: 8px;
      font-size: 16px;
      font-weight: bold;
    }
    .film-starring {
      font-size: 12px;
      font-weight: lighter;
      padding-bottom: 10px;
    }
    .film-about-film-italic {
      font-size: 12px;
      padding-top: 10px;
      font-style: italic;
    }
    .film-about-film-text1 {
      font-size: 12px;
      padding-top: 10px;
      font-weight: lighter;
    }
    .film-premiere-label {
      padding-top: 14px;
      font-size: 16px;
      font-weight: bold;
    }
    .film-premiere-text {
      font-size: 12px;
      padding-top: 5px;
      font-weight: lighter;
    }
    .film-iks {
      font-size: 28px;
      padding-top: 20px;
      padding-bottom: 30px;
      a {
        img {
          width: 45px;
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .film {
    .video-container {
      .play-video-btn {
        position: absolute;
        z-index: 10;
        width: 50px;
        height: 50px;
        top: 35%;
        left: 44%;
        cursor: pointer;
      }
    }
  }
}
