.swiper {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.logo {
  width: 120px;
  z-index: 4;
  position: fixed;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
}

.swiper-button-prev,
.swiper-button-next {
  color: white;
}

@media only screen and (max-width: 450px) {
  .logo {
    left: 56%;
  }
}
