.contact {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: auto 20%;

  &__title {
    width: 100%;
    padding-top: 10vh;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 26px;
  }

  &__email {
    width: 100%;
    display: block;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    margin-top: 70px;

    &__label {
      margin-bottom: 10px;
    }
  }

  &__networks {
    grid-row: 2;
    display: flex;
    justify-content: center;
    bottom: 20;

    a {
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
      text-decoration: none;
      color: $black;
      font-size: 36px;
    }
  }
}
