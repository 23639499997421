.header {
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  background: $black;
  color: $white;
  overflow-y: auto;
  left: 0;
  z-index: 100000;

  .header-content {
    display: grid;
    grid-template-rows: auto 40px;
    width: 80%;
    height: calc(100vh - 100px);
    margin: 50px 10%;

    &__title {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 2px;
    }

    &__items {
      margin-top: 50px;
      border-top: 1px solid $white;

      &__item {
        padding: 20px 0;
        border-bottom: 2px solid $white;
        font-size: 20px;
        color: white;
        cursor: pointer;
        &__navbar {
          padding: 10px 0;
          border-bottom: 0.5px solid $white;
          font-size: 12px;
          font-weight: 100;
        }
      }
    }

    &__bottom {
      grid-row: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;

      &__left {
        display: flex;

        span {
          cursor: pointer;
        }
      }

      &__right {
        display: flex;

        a {
          margin-left: 10px;
          cursor: pointer;
          text-decoration: none;
          color: $white;
          font-size: 26px;
        }
      }
    }
  }
}

.header-hide {
  display: none;
}

.header-logo {
  position: fixed;
  z-index: 100001;
  right: 0;
  top: 0;
  background-color: $white;
  width: 10vw;
  max-width: 40px;
  cursor: pointer;
  height: 10vw;
  max-height: 40px;
  border: 1px solid $white;
  border-radius: 50%;
  color: $white;
}

.header-logo-hide {
  background-color: $black;
  border-color: $black;
  color: $black;
}

@media only screen and (max-height: 700px) {
  .header {
    .header-content {
      &__items {
        &__item {
          padding: 15px 0;
          font-size: 20px;
        }
      }
    }
  }
}
