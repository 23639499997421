.video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  top: 0;
  left: 0;
}

.video-content {
  z-index: 2;
  width: 100%;
  height: 100vh;
  position: absolute;
  color: white;
  font-weight: bold;

  .title {
    position: absolute;
    width: 100%;
    top: 10%;
  }

  &__bottom {
    position: absolute;
    width: 100%;
    bottom: 8%;
    font-size: 18px;
    .year {
      font-size: 18px;
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .subtitle {
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 28px;
      a {
        padding-top: 20px;
        img {
          width: 15%;
        }
      }
    }
    .description {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
}

.img-homepage-mobile {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
