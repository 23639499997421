.manifest {
  width: 100%;
  display: grid;
  grid-template-rows: auto 40%;
  height: auto;
  z-index: 100;
  &__title {
    width: 100%;
    padding-top: 10vh;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 26px;
  }

  &__reel {
    width: 100%;
    display: block;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    margin: 35px 0;

    a {
      text-decoration: none;
      color: $black;
    }
    .video-container {
      .manifest-div-video-img {
        width: 80vw;
        height: 80vh;
        margin-left: 10vw;
        .img-manifset {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .play-video-btn-manifest {
          position: absolute;
          z-index: 10000;
          width: 80px;
          height: 80px;
          margin-top: 20%;
          margin-left: 37%;
          cursor: pointer;
        }
      }
    }
  }

  &__subtitle {
    width: 70%;
    margin-left: 15%;
    display: block;
    justify-content: center;
    text-align: center;
    font-size: 14px;
  }

  &__text {
    grid-row: 2;
    width: 70%;
    padding-bottom: 10vh;
    margin-left: 15%;
    display: block;
    justify-content: center;
    text-align: center;
    font-size: 14px;
  }
}

@media only screen and (max-width: 450px) {
  .manifest {
    .video-container {
      .manifest-div-video-img {
        width: 100%;
        margin-left: 0vw;
        .play-video-btn-manifest {
          margin-top: 70%;
          margin-left: 40%;
        }
      }
    }
  }
}
