.member {
  width: 100%;
  display: grid;
  grid-template-rows: auto 40%;
  height: 100vh;

  &__title {
    width: 100%;
    padding-top: 8vh;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 26px;
  }

  &__name {
    width: 100%;
    display: block;
    justify-content: center;
    text-align: center;
    font-size: 26px;
    margin: 50px 0 20px 0;
  }

  &__subtitle {
    width: 250px;
    text-align: center;
    font-size: 13px;
    margin: auto;
  }

  &__text {
    grid-row: 2;
    width: 70%;
    padding-bottom: 5px;
    margin-left: 15%;
    justify-content: center;
    text-align: center;
    font-size: 14px;

    &__up {
      margin-bottom: 10px;
    }
  }
}
