.action {
  width: 100%;
  display: grid;
  grid-template-rows: auto 40%;
  height: 100vh;

  &__title {
    width: 100%;
    padding-top: 8vh;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 26px;
  }

  &__subtitle {
    width: 100%;
    display: block;
    justify-content: center;
    text-align: center;
    font-size: 13px;
    margin: 20px 0;
  }

  img {
    width: 70%;
    max-width: 370px;
    height: auto;
    display: flex;
    margin: auto;
  }

  &__text {
    grid-row: 2;
    width: 70%;
    padding-bottom: 10vh;
    margin-left: 15%;
    justify-content: center;
    text-align: center;
    font-size: 14px;

    &__text-up {
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-height: 700px) {
  .action {
    height: auto;
    padding-bottom: 10px;
    &__text {
      height: auto;
      &__text-up {
        margin-bottom: 0;
      }
    }
  }
}
