#lottie {
  background-color: none;
  background-repeat: no-repeat;
  max-width: 15%;
  max-height: 15%;
  z-index: 4;
  position: fixed;
  left: 42.5%;
  top: 40%;
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;
  opacity: 1;
}

@media only screen and (max-width: 450px) {
  #lottie {
    left: 25%;
    max-width: 50%;
    max-height: 50%;
  }
}
